<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
    userCard: {
        type: Object,
        required: true,
    },
});
const config = computed(() => props.userCard.card.config_visual);
const stampConfig = computed(() => {  

  const res = props.userCard.card.config.filter((item) => item.type === 'stamp');
  return res.length > 0 ? res : undefined;
})
const stepValues = computed(() => {
  
  if (!stampConfig.value) {
    return undefined;
  }
  
  return stampConfig.value[0].steps.map((item) => item.key)
});

const steps = computed(() => listSteps());

const listSteps = () => {
  
  if (!stampConfig.value) {
    return undefined;
  }
  
  let maxValue = 0;
  let maxStamp = 10;
  stampConfig.value[0].steps.map((item) => {
    maxValue = Math.max(maxValue, item.key);
  })
  
  let minStamp = props.userCard.stamps;
  if (minStamp > maxValue - 10){
    minStamp = maxValue - 9;
  }

  if (minStamp <= 0) {
    minStamp = 1;
  }

  if(maxValue < maxStamp) {
    maxStamp = maxValue
  }
  
  
  let result = [];
  for(let i = 0; i < maxStamp; i++){
    result.push(i + minStamp)
  }
  
  return result;
}

</script>

<template>
  <div 
    class="card"
    :style="`
      --text-primary:  ${config.text_color};
      --text-label:  ${config.label_color ?? config.text_color};
      background-color: ${config.background_color};
    `"
  >
    <div class="card_header">
      <img v-if="config.logo" height="35" :src="config.logo" alt="logo" />
      <div class="card_header_text">
        <div v-if="userCard.card_view.second_left_title && userCard.card_view.second_left_value" class="card_header_text_item">
          <span class="mr-9-label ttu medium">{{userCard.card_view.second_left_title}}</span>
          <span class="mr-14-prim medium">{{
            userCard.card_view.second_left_value
          }}</span>
        </div>
        <div v-if="userCard.card_view.second_right_title && userCard.card_view.second_right_value" class="card_header_text_item">
          <span class="mr-9-label ttu medium">{{userCard.card_view.second_right_title}}</span>
          <span class="mr-14-prim medium">
            {{userCard.card_view.second_right_value}}
          </span>
        </div>
      </div>
    </div>
    <div class="card_data">

      <ul v-if="steps && steps.length > 1" class="card_data_presents">
        <li
          v-for="(indexNumber, index) of steps"
          :key="index"
          class="card_data_presents_item"
        >
          <img 
            width="40"
            height="37"
            v-if="stepValues && stepValues.includes(indexNumber) && userCard.stamps >= indexNumber" 
            src="/img/stamp-congratulation.png" 
            alt=""
          >
          <img 
            v-else-if="userCard.stamps >= indexNumber" 
            src="/img/stamp-green-square.png" 
            width="40"
            height="37"
            alt=""
          >
          <img 
            width="40"
            height="37"
            v-else-if="stepValues && stepValues.includes(indexNumber)" 
            src="/img/stamp-event.png" 
            alt=""
          >
          <img 
            v-else 
            src="/img/stamp-grey-square.png" 
            width="40"
            height="37"
            alt=""
          >
        </li>
      </ul>
      <img 
          class="card_data_cover"
          v-else-if="config.backgound_file" 
          :src="config.backgound_file" 
          alt="cover" 
        />
      <div v-else class="card_data_empty"></div>
      <div class="card_data_mid">
        <div v-if="userCard.card_view.left_title && userCard.card_view.left_value" class="card_data_mid_item">
          <span class="mr-9-label ttu medium">{{userCard.card_view.left_title}}</span>
          <span class="mr-14-prim medium ttl"
            >{{ userCard.card_view.left_value }} 
          </span>
        </div>
        <div v-if="userCard.card_view.right_title && userCard.card_view.right_value"  class="card_data_mid_item_end">
          <span class="mr-9-label ttu medium">{{userCard.card_view.right_title}}</span>
          <span class="mr-14-prim medium">
            {{ userCard.card_view.right_value }}
          </span>
        </div>
      </div>
    </div> 
    <img 
      v-if="userCard.lending_qr" 
      :src="userCard.lending_qr" 
      height="120"
      alt="qr"
    >
    <div v-else class="card_empty_qr"></div>
  </div>
</template>

<style scoped lang="scss">
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 270px;
  height: fit-content;
  display: flex;
  box-sizing: border-box;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-bottom: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.13);
  border-radius: 12px;
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 6px;
    padding: 0px 16px;
    box-sizing: border-box;

    & img {
        border-radius: 4px;
    }

    &_text {
      display: flex;
      gap: 14px;
      width: 100%;
      justify-content: flex-end;

      &_item {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: end;
      }
    }
  }

  &_data {
    width: 100%;

    &_cover {
      width: 100%;
      height: 100px;
    }
    &_presents {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      margin: 0px 15px 15px 15px;

      &_item {
        &_step {
          font-family: Manrope;
          font-size: 11.76px;
          font-style: normal;
          font-weight: 500;
          line-height: 15.68px;
          border-radius: 7.84px;
          background: rgba(255, 255, 255, 0.4);
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &_mid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
      padding: 8px 16px 0px 16px;
      box-sizing: border-box;

      &_item {
        display: flex;
        flex-direction: column;
        width: 50%;
        &_end {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    &_empty {
      width: 100%;
      height: 100px;
      background: rgba(78, 127, 255, 0.25);
      margin-bottom: 12px;
    }
  }

  &_empty_qr {
    padding-bottom: 140px;
  }
}
</style>
